import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  PlanBnr,
  Access,
  ImgList,
  InfoTable,
  FacilityPostBtns,
  FacilityDNav,
  KitchenList,
  AmenitieList,
  FaqList,
  wovnCheck,
} from '../../../components/_index';


// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const [floorPdf, setFloorPdf] = useState('floorPlan_goshonishi_ja01.pdf')
  wovnCheck(function (wovnCode: string) {
    const pdffile = wovnCode !== 'ja' ? 'floorPlan_goshonishi_en.pdf' : 'floorPlan_goshonishi_ja01.pdf';
    setFloorPdf(pdffile)
  });
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="facility_d" className="nishijin-villa under">
        <div id="facility_dMv">
          <div className="mvCont">
            <p className="ico">#6</p>
            <h2 className="facility_dMvTit">京の温所 御所西<span>GOSHONISHI</span></h2>
            <div className="mvImg" style={{ backgroundImage: 'url(/images/facility/1d6659a725f9a7e558e8b4aac6df3313.jpg)' }}></div>
          </div>
          <FacilityDNav data={[
            {
              anchor: "#facility_dInfo",
              label: <>施設情報</>,
            },
            {
              anchor: "#facility_dAmenitie",
              label: <>アメニティ</>,
            },
            {
              anchor: "#facility_dAccess",
              label: <>アクセス</>,
            },
            {
              anchor: "#facility_dPlan",
              label: <>プラン一覧</>,
            },
            {
              anchor: "#facility_dFaq",
              label: <>よくあるご質問</>,
            },
          ]} />
        </div>
        <div id="facility_dWrap" className="wd">
          <div id="facility_dInfoWrap">
            <section id="facility_dInfoAbout">
              <h2 className="tit">クリエイティブユニット「graf」が手がける<br />光と影 季節を豊かに感じる京町家</h2>
              <p>御所西は京都御所からほど近く、アクセスのよい京都市内の中心地にありながら、閑静な住宅街。<br />その中に建つ築100年を超える京町家をクリエイティブユニット「graf」が手がけ、光と影、季節を豊かに感じる「住まい」としてリノベーションしました。<br />
                木漏れ日が静かに変化していく庭とつながったリビング、トップライトと吹き抜けでつながるキッチン＆ダイニングとライブラリー、2階の奥にある秘密の小部屋のような和室など、お気に入りの居場所でゆっくりとお過ごしください。</p>
              <FacilityPostBtns data={[
                {
                  href: `/assets/files/facility/${floorPdf}`,
                  blank: true,
                  label: <>間取り図<small>（PDF）</small></>,
                },
                {
                  href: "/news/recommend/#goshonishi",
                  blank: false,
                  label: <>周辺のおすすめ</>,
                },
              ]} />
            </section>
            <section id="facility_dInfo">
              <h2 className="tit">INFORMATION</h2>
              <p className="post">京の温所 御所西 #6<br /><a href="https://goo.gl/maps/pjypSSEbJdYppm7A8" target="_blank">京都市上京区衣棚通椹木町上る門跡町291</a>
              </p>
              <InfoTable data={[
                {
                  label: <>広さ</>,
                  value: <>2階建て83平米（1棟貸切）</>,
                },
                {
                  label: <>定員</>,
                  value: <>1～4名</>,
                },
                {
                  label: <>部屋</>,
                  value: <>&lt;1階&gt; キッチン＆ダイニング　リビング 庭　風呂 洗面 トイレ<br />
                    &lt;2階&gt; ベッドルーム ライブラリー 和室</>,
                },
                {
                  label: <>寝具</>,
                  value: <>シングルベッド2台　ふとん2組（3名よりご用意）</>,
                },
                {
                  label: <>設備</>,
                  value: <>エアコン、空気清浄機、洗濯機、床暖房、浄水器（クリンスイ）</>,
                },
                {
                  label: <>テレビ</>,
                  value: <>あり（19インチ ポータブルタイプ）<br />※インターネット、機器等との接続不可</>,
                },
                {
                  label: <>スピーカー</>,
                  value: <>Bluetoothスピーカー</>,
                },
                {
                  label: <>Wi-Fi</>,
                  value: <>無料Wi-Fi（無線LAN）</>,
                },
                {
                  label: <>禁煙</>,
                  value: <>建物・敷地内　全面禁煙</>,
                },
              ]} />
            </section>
          </div>
          <div id="facility_dGallery" className="facility_dSection">
            <ImgList data={[
              '/images/facility/cbcd186da8a69625e8ec4675dca7710f.jpg',
              '/images/facility/b973d8bd14e5eda61d6344329495a5bd.jpg',
              '/images/facility/a474cbff8e065720552c53d3827f35fe.jpg',
              '/images/facility/977205f118db73f7e4f311bfd6575d36.jpg',
              '/images/facility/ef5fa0c8b7dfeb46268e0754638d7ab2.jpg',
              '/images/facility/245d664ce40a9757689ebf918f8ed6cc.jpg',
              '/images/facility/b1a12b6c2d7f9a4e7acb387de94cc4ae.jpg',
              '/images/facility/7829ac2aeac7738b59a5f9ff3e8a4c8c.jpg',
              '/images/facility/8ff36efe092abe7123d9ab389609d243.jpg',
            ]} />
          </div>
          <section id="facility_dIntro" className="facility_d facility_dSection">
            <h2 className="facility_dTit">さまざまな表情を見せる光と影、季節を豊かに感じる京町家</h2>
            <p>築100年以上の京町家「京の温所 御所西」は、長い間空き家となりかなり傷んでいました。
              この京町家の個性である、1階の坪庭から入る静かな光や天井裏に佇む伝統工法で組まれた梁や柱などを生かしつつ、光と影、季節を豊かに感じる「住まい」として新たな命を吹き込みました。<br />家の中央には吹き抜けを配し、トップライトから降り注ぐ光と影が、2階のライブラリーや階下のダイニングに印象的な陰影をもたらします。<br />ブラックチェリー素材で床、壁、天井をぐるりと囲んだリビングは庭とつながり、刻々と変化していく木漏れ日や季節の移ろいを絵画のように楽しむことができます。<br />また、2階の小さな入口の奥にある和室は、低い天井が秘密の小部屋のよう。不思議と落ち着く空間です。
            </p>
            <ImgList data={[
              '/images/facility/b92a4d0bc74ac7fc872d3b2ec1551482.jpg',
              '/images/facility/1d78b2545a5bc44ce8651b5bb00718a4.jpg',
              '/images/facility/a124c5110fc36256798aca9f7340140b.jpg',
            ]} />
          </section>
          <section id="facility_dKitchen" className="facility_d facility_dSection">
            <div>
              <h2 className="facility_dTit">料理や食事が楽しくなるキッチン＆ダイニング</h2>
              <p>食卓を囲むダイニングは、テーブルの上に大きく広がる吹き抜けが開放的。天窓から降り注ぐ光が変化したり位置を変えてダイニングに様々な表情をつくってくれます。<br />キッチンにはちょっとした温めはもちろん、本格的な料理までできるように冷蔵冷凍庫、IHの三口コンロ、グリルにオーブンレンジ、また用と美を兼ね備えた調理器具や、食器、カトラリーもご用意。<br />「graf」オリジナルの指先にフィットする使いやすいカトラリーや、野菜をモチーフにしたなべ敷きや栓抜きなど、料理や食事が楽しくなるものをそろえました。
              </p>
            </div>
            <ImgList data={[
              '/images/facility/b27e8111d4ff53695ad071929db6a68e.jpg',
              '/images/facility/f727345a5bcbe8145269a6efc2af6799.jpg',
              '/images/facility/781424f5ed4a47b081e2226e4d8c21e4.jpg',
            ]} />
            <KitchenList />
          </section>
          <section id="facility_dFeature" className="facility_d facility_dSection">
            <h2 className="facility_dTit">つくり手のぬくもりを感じる家具やアートワーク</h2>
            <p>暮らしにまつわる様々な要素をものづくりから考え実践するクリエイティブユニット「graf」。「京の温所 御所西」では建物の設計・デザインにとどまらず、キッチンや家具、照明や把手にいたるまで、「graf」がデザインをおこしました。それを日本全国の腕のいいつくり手たちが製作しています。
              また、アートワークもアーティストたちと対話を重ね、光と影、季節を豊かに感じる京町家の空間に合う作品を「graf」が選定。静かで抽象的だけど、どこかぬくもりのある印象も感じさせる作品たちです。<br />つくり手たちのぬくもりに包まれた空間で、ゆっくりとおくつろぎください。</p>
            <ImgList data={[
              '/images/facility/a483c4bc258fa633d9bc399166e3d2ed_1.jpg',
              '/images/facility/de8075220335ce9d3e20b0f47208feca_1.jpg',
              '/images/facility/1edfccc1423bf27285c6660b45f637c5_1.jpg',
            ]} />
          </section>
          <AmenitieList data={[
            '/images/facility/a42fc6d991fe4fd47effaeae5b7a6374.jpg',
            '/images/facility/amenities_bath.jpg',
            '/images/facility/amenities_pajamas.jpg',
          ]} />
          <section id="facility_dAccess">
            <Access data={{
              train: <>地下鉄烏丸線「丸太町」駅2番出口　徒歩約7分</>,
              bus: <>「烏丸丸太町」下車　徒歩約5分</>,
              taxi: "",
              parking: <>駐車場はございません。（近隣パーキング：徒歩5分圏内数か所あり）</>,
              img: "",
              maplink: "https://goo.gl/maps/pjypSSEbJdYppm7A8",
              map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d816.8810066244745!2d135.7571157!3d35.0186182!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x76eb6faed636c9d0!2z5Lqs44Gu5rip5omAIOW-oeaJgOilvyAjNg!5e0!3m2!1sja!2sjp!4v1572248744463!5m2!1sja!2sjp",
            }} />
          </section>
          <section id="facility_dPlan">
            <PlanBnr roomcd="0000001670" />
          </section>
          <FaqList />
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
